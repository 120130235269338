a {
  -webkit-transition: color .4s ease;
  transition: color .4s ease;
  text-decoration: none;
  border-bottom: 0 solid rgba(69,69,69,0);
  background-color: transparent;
  color: #2d9dcc;
}

a:hover {
  color: #2d9dcc;
}

a:hover svg g path {
  fill: #2d9dcc;
}

body {
  margin: 0;
  background-color: #e9ebed;
  font-family: Oxygen;
  font-weight: 300;
  color: #444;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
