.box {
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.box .row {
    border: 1px dotted grey;
}

.box .row.header {
    flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

.box .row.content {
    flex: 1 1 auto;
}

.box .row.footer {
    flex: 0 1 40px;
}

.Landing {
    text-align: center;
    width: 100vw;
}

.content-container {
    margin: 0 10vw;
}

.html-wrap {
    background-color: #e9ebed;
    padding-left: 1.5em;
}

.content {
    text-align: left;
    font-size: calc(7px + 2vmin);
    background-color: #e9ebed;
    font-family: Oxygen;
    font-weight: 300;
    color: #444;
    line-height: 1.5;
}

.content h1 {
    text-align: center;
}

.content img {
  float: right;
  margin: 0 0 2.5% 2.5%;
  max-width: 50%;
}

.Landing-header {
    margin: 1.5vh auto 2.5vh;
    text-align: center;
}

.Landing-logo {
    margin: 0;
    height: 20vmin;
    pointer-events: none;
    border-radius: 50%;
}

.Landing-social {
    margin: 1.5vh auto;
}

.Landing-social a {
    margin: 0 1vw;
}

.Landing-social svg {
    width: 7vmin;
    height: 7vmin;
}

.Landing-subtitle {
    margin: 0 0 0 0;
    font-size: 2.7vmin;
    font-weight: inherit;
}

.Landing-title {
    margin: 0;
    font-size: 6.5vmin;
    font-weight: inherit;
}

/****** Nav / Menu ***************************/
nav.Landing-menu {
    margin: 2.5vmin auto 1.5vmin;
}

nav.Landing-menu ul {
    list-style-type: none;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    width: 60vw;
    text-align: center;
}

nav.Landing-menu ul li {
    display: inline-block;
    padding: 0 3vmin 0 0;
}

nav.Landing-menu ul li a,
nav.Landing-menu ul li div {
    font-size: calc(7px + 2vmin);
    color: #444;
    transition-property: color;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    transition-delay: 0s;
    cursor: pointer;
}

nav.Landing-menu ul li a:hover,
nav.Landing-menu ul li div:hover {
    color: #2d9dcc;
    cursor: pointer;
}

nav.separator {
    border-bottom: 1px solid #00b0cc;
}

@media (max-device-width: 480px) {
    nav.Landing-menu ul {
        max-width: 80vw;
    }
}
@media (max-width: 480px) {
    nav.Landing-menu ul {
        max-width: 80vw;
    }
}
@media (min-width: 481px) {
    nav.Landing-menu ul {
        max-width: 60vw;
    }
}
/****** Nav / Menu ***************************/
