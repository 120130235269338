.menuItemList {
  float: left;
  position: absolute;
  margin: 0;
  padding: 0.25em 0.5em !important;
  width: 25vw !important;
  display: block;
  list-style: none;
  background-color: #e9ebed;
  right: auto;
  left: auto;
  z-index: 2;
  border-bottom: 2px solid #00b0cc;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  text-align: left !important;
}

.menuItemListItem a {
  display: block;
  width: 25vw;
  text-align: left;
}

.menuIcon {
  font-size: 0.75em;
  padding-left: 0.25em;
}
