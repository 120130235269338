.back-to-top-button {
  font-size: calc(1em + 2.2vw);
}

.back-to-top-button:focus {
  outline: none;
}

.back-to-top-button svg {
  color: #555;
  transition-property: color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.back-to-top-button svg:hover {
  color: #2d9dcc;
}
