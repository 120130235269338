.Landing {
  text-align: center;
}

.content {
  margin: 0 auto 2.5vh auto;
  text-align: left;
}

.content h1 {
  text-align: center;
}

.content p img {
  float: right;
  margin: 0 0 2.5% 2.5%;
  max-width: 50%;
}

.content table {
  margin: 0 auto 0 auto;
}

.content table thead tr th {
  text-align: center;
}

.content table img {
  max-width: 25vw;
}

.content div.player {
  margin: 0 auto 0 auto;
}

.Landing-header {
  margin: 1.5vh auto 2.5vh;
  text-align: center;
}

.Landing-logo {
  margin: 0;
  height: 20vmin;
  pointer-events: none;
  border-radius: 50%;
}

.Landing-social {
  margin: 1.5vh auto;
}

.Landing-social a {
  margin: 0 1vw;
}

.Landing-social svg {
  width: 7vmin;
  height: 7vmin;
}

div.Landing-social a svg g path {
  fill: #444;
  transition-property: fill;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
}
div.Landing-social a:hover svg g path {
  fill: #2d9dcc;
}

.Landing-subtitle {
  margin: 0 0 0 0;
  font-size: 2.7vmin;
  font-weight: inherit;
}

.Landing-title {
  margin: 0;
  font-size: 6.5vmin;
  font-weight: inherit;
}

.Landing-footer {
  background-color: #28272d;
  overflow: auto;
  padding-top: 5vh;
  clear: both;
}

.Landing-social-container {
  padding-bottom: 5vh;
  padding-top: 1vh;
  margin: 0 auto;
}

/****** Feature Bubbles **********************/
div.feature-frame {
  padding-top: 2vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.feature-slot {
  width: 25%;
  text-align: center;
}

.circular {
  margin: 0 auto;
  width: 9vw;
  height: 9vw;
  border-radius: 75vw;
  border: 0.5vw solid #1b75bb;
  cursor: pointer;
  -webkit-border-radius: 75vw;
  -moz-border-radius: 75vw;
  cursor: pointer;
}

.theButton {
  border-color: #1b75bb;
  background: url(https://s3.us-east-2.amazonaws.com/content.alan.codes/the-button)
    no-repeat;
  background-size: 10vw;
  background-position: 50% 50%;
}

.mouseDroid {
  border-color: #bada55;
  background: url(https://s3.us-east-2.amazonaws.com/content.alan.codes/mouse-droid-inside)
    no-repeat;
  background-size: 10vw;
  background-position: 50% 100%;
}

.orbNotifier {
  border-color: #009bcd;
  background: url(https://s3.us-east-2.amazonaws.com/content.alan.codes/OrbNotifier-Alert-sm)
    no-repeat;
  background-size: 9vw;
  background-position: 50% 50%;
}

.drumBot {
  border-color: #ff1a53;
  background: url(https://s3.us-east-2.amazonaws.com/content.alan.codes/DD5K-Guts)
    no-repeat;
  background-size: 16vw;
  background-position: 50% 50%;
}

/****** EO Features **************************/

/****** Nav / Menu ***************************/
nav.Landing-menu {
  margin: 2.5vmin auto 1.5vmin;
}

nav.Landing-menu ul {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  width: 60vw;
  text-align: center;
}

nav.Landing-menu ul li {
  display: inline-block;
  padding: 0 4vmin 0 0;
}

nav.Landing-menu ul li a,
nav.Landing-menu ul li div {
  color: #444;
  transition-property: color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  transition-delay: 0s;
  cursor: pointer;
}

nav.Landing-menu ul li a:hover,
nav.Landing-menu ul li div:hover {
  color: #2d9dcc;
  cursor: pointer;
}

nav.Landing-menu ul li a.active,
nav.Landing-menu ul li div.active {
  color: #2d9dcc;
}

nav.separator {
  border-bottom: 1px solid #00b0cc;
}

div.menu-item-container:has(a.active) {
  color: #2d9dcc;
}
/****** Nav / Menu ***************************/

/****** Markdown Shortcode *******************/
div.image-container {
  max-width: 25vw;
}

div.image-header-container {
  text-align: center;
}

img.encapsulated-image {
  max-width: 100%;
  max-height: 50vh;
}

figcaption.image-caption {
  font-size: 0.8rem;
}

/*********************************************/

/****** Responsive ***************************/
@media (max-device-width: 480px) {
  .content {
    font-size: 1rem;
  }

  .content-container {
    margin: 0 14vw;
  }

  nav.Landing-menu ul {
    max-width: 80vw;
    font-size: 1rem;
  }

  div.feature-frame {
    font-size: 0.6rem;
  }
}
@media (max-width: 480px) {
  .content {
    font-size: 1rem;
  }

  .content-container {
    margin: 0 10vw;
  }

  nav.Landing-menu ul {
    max-width: 80vw;
    font-size: 1rem;
  }

  div.feature-frame {
    font-size: 0.6rem;
  }
}
@media (min-width: 481px) {
  .content {
    font-size: 1rem;
  }

  .content-container {
    margin: 0 18vw;
  }

  nav.Landing-menu ul {
    max-width: 60vw;
    font-size: 1.5rem;
  }

  div.feature-frame {
    font-size: 1.5rem;
  }
}
